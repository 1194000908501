import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import './family-modal.scss';

import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const modalColor = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'nineth',
];

const FamilyModal = React.forwardRef((props, ref) => {
  const { member, index } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setOpen] = useState(false);

  const onButtonClick = e => {
    setOpen(true);
  };

  const onModalClose = () => {
    setOpen(false);
  };

   
  const datalayerPersonajes = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'pop_up',
      action_type: 'print',
      label: labelText,
      single_item_id: '',
      single_item_name: 'Nuestra Familia',
      link_text: 'Icono +',
      link_url: location,
      link_domain: 'familia.com.co'
    })

    pushDataLayerEvent({
      event: 'Interacciones_Pop-ups',
      category: 'Nosotros',
      action: 'view',
      label: labelText,
      sub_category: 'Nuestra Familia',
      location: location,
      user_data: [],
      log_status: '',
    })
  }
  
  const datalayerCloseFamilyModal = (labelText) => {
    let location=''
    if (typeof window !== 'undefined') {
      location= window.location.href
    }

    pushDataLayerEvent({
      event: 'pop_up',
      action_type: 'close',
      label: labelText,
      single_item_id: '',
      single_item_name: 'Nuestra Familia',
      link_text: 'Icono x',
      link_url: location,
      link_domain: 'familia.com.co'
    })
  }

  return (
    <div ref={ref}>
      <div className="f-family-card">
        <div className="f-family-card-background" />
        <img src={member.imagen[0].file.url} onClick={(e) => {onButtonClick(e); datalayerPersonajes(member.title) }} />
        <div className="f-family-card-name">
          <label>{member.title}</label>
          <i className={`far fa-lg fa-plus-circle`} onClick={(e) => {onButtonClick(e); datalayerPersonajes(member.title) }} />
        </div>
        <Dialog
          classes={{ root: 'f-family-modal' }}
          fullScreen={fullScreen}
          open={isOpen}
          onClose={onModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogActions>
            <div className="f-family-modal-action">
              <i
                className={`far fa-3x fa-times-circle f-close-circle`}
                onClick={() => {onModalClose(); datalayerCloseFamilyModal(member.title)}}
              />
            </div>
          </DialogActions>

          <DialogContent>
            <div className="f-family-modal-card">
              <div className="f-family-modal-image-container">
                <div
                  className={`f-family-modal-background ${modalColor[index]}`}
                ></div>
                <img src={member.imagen[0].file.url} alt="" />
              </div>

              <div className="f-family-modal-text-container">
                <label>{member.title}</label>
                <p>{member.description.description}</p>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
});

export default FamilyModal;
