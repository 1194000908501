import React from 'react';
import './who-content.scss';

export default ({ data }) => {
  return (
    <div className="f-who-container">
      <div className="f-who-content">
        <div className="f-who-section">
          <div className="f-who-text">
            <h1 className="f-who-title">{data[0].content[0].value}</h1>
            <p>{data[1].content[0].value}</p>
          </div>
          <div className="f-who-media-container">
            <div className="f-who-logo">
              <img src={data[2].data.target.fields.file.es_CO.url} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
