import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import FamilyModal from '../../molecules/family-modal/family-modal';

import './family-content.scss';

export default () => {
  const data = useStaticQuery(graphql`
    query FamiliaBearsQuery {
      allContentfulNosotros {
        nodes {
          node_locale
          familiaBears {
            imagen {
              file {
                url
              }
            }
            title
            description {
              description
            }
          }
        }
      }
    }
  `);
  
  return (
    <div className="f-family-container">
      <div className="f-family-content">
        {data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].familiaBears.map(
          (member, index) => (
            <FamilyModal key={index} index={index} member={member} />
          )
        )}
      </div>
    </div>
  );
};
