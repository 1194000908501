import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useMobileMode from '../../../hooks/useMobileMode';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import { siteUrl } from '../../../shared/utils/siteUrl';
import { removeDoubleSlash } from '../../../shared/utils/urlUtil';
import './header-sostainibility.scss';

const datalayerSustainibilityOption = (labelText) => {      
  let location=''
  if (typeof window !== 'undefined') {
      location= window.location.href
  }
  pushDataLayerEvent({
    event: 'Interacciones_Sostenibilidad',
    category: 'Nosotros',
    action: 'click',
    label: labelText,
    sub_category: 'Sostenibilidad',
    second_sub_category: labelText,
    location: location,
    user_data: [],
    log_status: '',
  })
}

const changeUrlVideo = urlVideo => {
  return urlVideo.includes("youtube")
    ? urlVideo.replace("watch?v=", "embed/")
    : urlVideo.includes("youtu.be")
    ? urlVideo.replace(".be/", "be.com/embed/")
    : urlVideo
}

const HeaderSostainibility = ({ banner, urlVideoBanner, textoBanner, title, menuInfo, activeOption }) => {
  const { isMobile } = useMobileMode();

  return (
    <div className="f-sustainibility-header">
      {['es-CO', 'es-EC'].includes(process.env.LOCALE) ? (
        <section
          className="f-sustainibility-header-video"
          style={{backgroundImage: `url("${banner[isMobile ? 1 : 0]?.file.url}")`}}
        >
          <div className="f-sustainibility-header-video-wrapper">
            {textoBanner && (
              <div className="f-sustainibility-header-video-text">
                {documentToReactComponents(textoBanner.json)}
              </div>
            )}
            {urlVideoBanner && (
              <div className="f-sustainibility-header-video-player">
                <iframe
                  width="100%"
                  height="100%"
                  src={changeUrlVideo(urlVideoBanner)}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
                  allowfullscreen
                />
              </div>
            )}
          </div>
        </section>
      ) : (
        <figure className="f-sustainibility-header-image">
          <img
            src={banner[isMobile ? 1 : 0]?.file.url}
            alt={banner[isMobile ? 1 : 0]?.description}
          />
        </figure>
      )}

      {(title || menuInfo) && (
        <div className="f-sustainibility-header-title">
          {title ? (
            <h1>{title}</h1>
          ) : (
            <div className="f-sustainibility-header-menu">
              {menuInfo.map((option, index) => (
                <a
                  className={
                    'f-sustainibility-header-menu-option' +
                    (activeOption === option.nombreCategoria ? ' active' : '')
                  }
                  href={siteUrl + removeDoubleSlash('/nosotros/sostenibilidad/' + option.slug)}
                  key={index}
                  onClick={() => datalayerSustainibilityOption(option.nombreCategoria)}
                >
                  <figure className="f-sustainibility-header-menu-option-image">
                    <img
                      src={option.logoCategoria.file.url}
                      alt={option.logoCategoria.description}
                    />
                  </figure>
                  <h2 className="f-sustainibility-header-menu-option-title">
                    {option.nombreCategoria}
                  </h2>
                </a>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderSostainibility;
