import { Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import SEO from '../../../shared/components/seo';
import Img from "gatsby-image"
import Family from './family-content';
import Sustainibility from './sustainibility-content';
import './we-content.scss';
import Who from './who-content';
import useMobileMode from '../../../hooks/useMobileMode';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'



const WeContent = ({ page = 0, ...props }) => {

  const isPR = process.env.LOCALE === "es-PR";

  const datalayerMenuButtons = (categoryName, subCategory, item) => {
    let location = ''
    if (typeof window !== 'undefined') {
      location = window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Menú',
      category: categoryName,
      action: 'click',
      label: subCategory || 'Ninguno',
      sub_category: subCategory || 'Ninguno',
      location: location,
      user_data: [],
      log_status: '',
    })
    pushDataLayerEvent({
      event: 'menu_click',
      action_type: 'Link',
      link_text: categoryName + ' > ' + subCategory,
      index: '3.' + item,
      menu_name: 'Submenú'
    })
  }
  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > div': {
        borderRadius: '50%',
        maxWidth: 6,
        width: '100%',
      },
    },
  })(props => (

    <Tabs {...props} TabIndicatorProps={{ children: <div /> }}>
      <Tab
        component={React.forwardRef((props, ref) => (
          <button {...props}>
            <a onClick={() => datalayerMenuButtons('Nosotros', 'Somos familia', 1)} href={"/nosotros/somos-familia"} className={"nostyle"}>
              <span className="MuiTab-wrapper" ref={ref}>Somos familia</span>
            </a>
            <span className="MuiTouchRipple-root"></span>
          </button>
        ))}
      ></Tab>
      {(isPR) ?
        <Tab
          component={React.forwardRef((props, ref) => (
            <button {...props}>
              <a onClick={() => datalayerMenuButtons('Nosotros', '¿Dónde estamos?', 1)} href={"/nosotros/donde-estamos"} className={"nostyle"}>
                <span className="MuiTab-wrapper" ref={ref}>¿Dónde estamos?</span>
              </a>
              <span className="MuiTouchRipple-root"></span>
            </button>
          ))}
        />
        :
        null
      }
      <Tab
        component={React.forwardRef((props, ref) => (
          <button {...props}>
            <a onClick={() => datalayerMenuButtons('Nosotros', 'Nuestra familia', 2)} href={"/nosotros/nuestra-familia"} className={"nostyle"}>
              <span className="MuiTab-wrapper" ref={ref}>Nuestra familia</span>
            </a>
            <span className="MuiTouchRipple-root"></span>
          </button>
        ))}
      />
      {(!isPR) ?
        <Tab
          component={React.forwardRef((props, ref) => (
            <button {...props}>
              <a onClick={() => datalayerMenuButtons('Nosotros', 'Sostenibilidad', 3)} href={"/nosotros/sostenibilidad"} className={"nostyle"}>
                <span className="MuiTab-wrapper" ref={ref}>Sostenibilidad</span>
              </a>
              <span className="MuiTouchRipple-root"></span>
            </button>
          ))}
        />
        :
        null}
    </Tabs>
  ));

  const data = useStaticQuery(
    graphql`
      query WE_QUERY {
        allContentfulNosotros {
          nodes {
            node_locale
            title
            metaTitle
            metaDescription {
              metaDescription
            }
            aboutAsPage {
              description {
                content {
                  content {
                    value
                  }
                  data {
                    target {
                      fields {
                        file {
                          es_CO {
                            url
                            fileName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            dondeEstamos {
              metaTitle
              metaDescription {
                metaDescription
              }
              slug
              banner {
                fluid(maxWidth: 1900, quality: 100) {
                  src
                  srcSet
                  base64
                  aspectRatio
                  sizes
                }
                description
              }
            }
            nuestraFamilia {
              metaTitle
              metaDescription {
                metaDescription
              }
            }
            environmentalSustainability {
              metaTitle
              metaDescription {
                metaDescription
              }
            }
          }
        }
      }
    `
  );

  const {
    title,
    metaTitle,
    metaDescription,
  } = data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0];
  const { isMobile } = useMobileMode();

  let filteredByCountry = data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0];
  const onTabChange = (e, newValue) => {
    const page =
      newValue === 0
        ? 'somos-familia'
        : (newValue === 1 && (!isPR)) ? 'nuestra-familia' :
          (newValue === 1 && (isPR))
            ? 'donde-estamos' :
            (newValue === 2 && (isPR)) ? 'nuestra-familia' : 'sostenibilidad'
      ;
    navigate(`/nosotros/${page}`);
  };

  let meta = {}

  switch (page) {
    case 1:
      meta = {
        metaTitleCategory: isPR ?
          data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].dondeEstamos.metaTitle
          : data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].nuestraFamilia.metaTitle,

        metaDescriptionCategory: isPR ?
          data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].dondeEstamos.metaDescription
          : data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].nuestraFamilia.metaDescription
      }
      break;
    case 2:
      meta = {
        metaTitleCategory: isPR ?
          data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].nuestraFamilia.metaTitle
          : data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].environmentalSustainability.metaTitle,

        metaDescriptionCategory: isPR ?
          data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].nuestraFamilia.metaDescription
          : data.allContentfulNosotros.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].environmentalSustainability.metaDescription,
      };
      break;
  }

  return (
    <>
      <div className="f-we-tabs">
        <StyledTabs
          value={page}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
        />
      </div>
      <div className="f-we-content">
        <SEO
          title={meta?.metaTitleCategory || metaTitle || title}
          description={meta?.metaDescriptionCategory?.metaDescription || metaDescription?.metaDescription || ''}
          lang="ES"
        />
        {page === 0 ? (
          <Who data={filteredByCountry.aboutAsPage.description.content} />
        ) : page === 1 ? (
          process.env.LOCALE === 'es-PR' ? (
            <Img
              className="f-image-map"
              fluid={
                filteredByCountry.dondeEstamos.banner[isMobile ? 1 : 0].fluid
              }
              alt={
                filteredByCountry.dondeEstamos.banner[isMobile ? 1 : 0]
                  .description
              }
            />
          ) : (
            <Family />
          )
        ) : page === 2 &&
          (process.env.LOCALE === 'es-PR') ? (
          <Family />
        ) : (
          <Sustainibility />
        )}
      </div>
    </>
  );
};

export default WeContent;